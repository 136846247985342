import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './TermsOfService.css';
import catchb from '../assets/images/catchb.svg'; // 로고 이미지 추가

// 서비스 이용약관 데이터를 날짜별로 관리
const termsOfServices = {
  '2024-09-01': {
    title: '서비스 이용약관',
    content: (
      <>
        <h2>제1조(목적)</h2>
        <p>
          Catch B(이하 “캐치비”) 서비스 이용약관에 동의한 사용자를 ‘회원’ (이하 “회원” 혹은 “이용자”), 본 서비스에 등록된 매장을 ‘아카데미’(이하 “아카데미”), 본 서비스에 등록된 레슨 제공자를 ‘코치’(이하 “코치”)라 합니다.
        </p>
        <p>
          본 약관은 주식회사 스윕시리즈(이하 “회사”)가 제공하는 Catch B 서비스(이하 “서비스”)를 이용하면서 회사와 회원의 권리·의무 및 책임 사항을 규정함을 목적으로 합니다.
        </p>

        <h2>제2조(이용약관의 효력 및 변경)</h2>
        <p>
          본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에 동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생합니다.
        </p>
        <p>
          회원이 온라인에서 본 약관의 “동의하기”버튼 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.
        </p>
        <p>
          회사는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자 기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
        </p>
        <p>
          회사가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 10일 전부터 적용일 이후 상당한 기간 동안 공지만을 하고, 개정 내용이 회원에게 불리한 경우에는 그 적용일자 30일 전부터 적용일 이후 상당한 기간 동안 각각 이를 서비스 홈페이지에 게시하거나 회원에게 전자적 형태(전자우편, SMS 등)로 약관 개정 사실을 발송하여 고지합니다.
        </p>
        <p>
          회사가 전항에 따라 회원에게 통지하면서 공지 또는 공지·고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에 승인한 것으로 봅니다. 회원이 개정약관에 동의하지 않을 경우 회원은 이용계약을 해지할 수 있습니다.
        </p>

        <h2>제3조(서비스의 내용)</h2>
        <p>회사가 제공하는 서비스는 아래와 같습니다:</p>
        <ul>
          <li>야구아카데미 정보제공/예약/결제: 전국 야구아카데미 업체에 대해서 정보를 제공하여 예약과 결제 서비스를 통해 간편하게 이용할 수 있도록 합니다.</li>
          <li>실내 야구레슨장 정보제공/예약/결제: 전국 실내 야구레슨장 업체에 대해서 정보를 제공하며 방문 상담과 결제 서비스를 통해 간편하게 이용할 수 있도록 합니다.</li>
          <li>야구트레이닝시설 정보제공/예약/결제/중개: 전국 야구트레이닝시설에 대해서 정보를 제공하며 방문 상담과 결제 서비스를 통해 간편하게 이용할 수 있도록 합니다.</li>
          <li>실외 야구장 정보제공/예약 중개: 전국 실외 야구장에 대해서 정보를 제공하며 예약 업체에 대해 예약을 중개하여 간편하게 이용할 수 있도록 합니다.</li>
          <li>야구 레슨 정보제공/예약 중개: 전국 야구 코치(프로) 대해서 정보를 제공하며 레슨을 중개하고 예약과 결제 서비스를 통해 간편하게 이용할 수 있도록 합니다.</li>
          <li>야구 정보 제공: 야구 전반에 대해서 정보성 글/이미지/영상을 제공합니다.</li>
        </ul>

        <h2>제4조(서비스 이용요금)</h2>
        <p>
          회사가 제공하는 서비스는 무료입니다. 단, 별도의 유료 서비스(예약/결제/프로모드 프리미엄 구독 서비스 등)의 경우 해당 서비스에 명시된 요금을 지불하여야 사용 가능합니다.
        </p>
        <p>무선 서비스 이용시 발생하는 데이터 통신료는 별도이며 가입한 각 이동통신사의 정책에 따릅니다.</p>
        <p>MMS 등으로 게시물을 등록할 경우 발생하는 요금은 이동통신사의 정책에 따릅니다.</p>

        <h2>제5조(서비스내용변경 통지 등)</h2>
        <p>
          회사가 서비스 내용을 변경하거나 종료하는 경우 회사는 서비스의 공지사항을 등을 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있습니다.
        </p>

        <h2>제6조(서비스이용의 제한 및 중지)</h2>
        <p>회사는 아래 각호에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다:</p>
        <ul>
          <li>회원이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우</li>
          <li>앱의 이용약관 또는 사용자 정책을 위반하는 경우</li>
          <li>서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</li>
          <li>전기통신사업법에 규정된 기간 통신사업자가 전기통신 서비스를 중지했을 경우</li>
          <li>국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때</li>
          <li>기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</li>
        </ul>
        <p>회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 회원에게 알려야 합니다.</p>

        <h2>제7조(정보제공 및 광고의 게재)</h2>
        <p>
          “회사”는 “회원”이 “서비스”이용 중 필요하다고 인정되는 다양한 정보를 공지사항이나 전자우편 등의 방법으로 “회원”에게 제공할 수 있습니다. 다만, “회원”은 관련법에 따른 거래관련 정보 및  고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 수신 거절을 할 수 있습니다.
        </p>
        <p>
          제1항의 정보를 전화 및 모사전송기기에 의하여 전송하려고 하는 경우에는 “회원”의 사전 동의를 받아서 전송합니다. 다만, “회원”의 거래관련 정보 및 고객문의 등에 대한 회신이 있어서는 제외됩니다.
        </p>
        <p>
          “회사”는 “서비스”의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편, 어플리케이션, 푸시메시지 등에 광고를 게재할 수 있습니다. 광고를 수신한 “회원”은 수신거절을 “회사”에게 할 수 있습니다.
        </p>
        <p>“이용자(회원, 비회원 포함)”는 회사가 제공하는 서비스와 관련하여 게시물 또는 기타 정보를 변경, 수정, 제한하는 등의 조치를 취하지 않습니다.</p>

        <h2>제8조(게시물의 저작권)</h2>
        <p>“회원”이 “서비스” 내에 게시한 “게시물”의 저작권은 해당 게시물의 저작자에게 귀속됩니다.</p>
        <p>
          “회원”이 “서비스” 내에 게시하는 “게시물”은 검색결과 내지 “서비스” 및 관련 프로모션 등에 노출될 수 있으며, 해당 노출을 위해 필요한 범위 내에서는 일부 수정, 복제, 편집되어 게시될 수 있습니다. 이 경우, 회사는 저작권법 규정을 준수하며, “회원”은 언제든지 고객센터를 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개 등의 조치를 요구할 수 있습니다.
        </p>
        <p>“회사” 제2항 이외의 방법으로 “회원”의 ‘게시물“을 이용하고자 하는 경우에는 전화, 팩스, 전자우편 등을 통해 사전에 ”회원“의 동의를 얻어야 합니다.</p>
        <p>“서비스” 내 모든 “게시물” 및 정보(레슨 리뷰, 가격정보, 컨텐츠, 아카데미 목록 등)의 크롤링 및 정부 수집을 금합니다.</p>

        <h2>제9조(게시물의 관리)</h2>
        <p>
          “회원‘의 ”게시물“이 “정보통신망법” 및 “저작권법” 등 관련법에 위반되는 내용을 포함하는 경우, 권리자는 관련법이 정한 절차에 따라 해당 “게시물”의 게시중단 및 삭제 등을 요청할 수 있으며, “회사”는 관련법에 따라 조치를 취하여야 합니다.
        </p>
        <p>
          “회사”는 서비스 이용약관 위반 여부 확인 및 분쟁해결의 목적으로 채팅 및 게시물에 대하여 기록을 저장하여 관리하며 모니터링 합니다. 특히 공공질서 및 미풍 양속에 반하는 표현, 타인의 개인 정보를 포함하는 표현, 회사 또는 타인의 명예를 손상시키거나 불이익을 주는 표현, 상업적 표현에 위배되는 표현은 금지됩니다.
        </p>
        <p>
          “회사”는 전항에 따른 권리자의 요청이 없는 경우에도 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련법에 위반되는 경우(사회통념상 올바르지 않거나 불쾌감을 주는 경우 등)에는 관련법에 따라 해당 “게시물”에 대해 임시조치 및 삭제 등을 취할 수 있습니다.
        </p>

        <h2>제10조(권리의 귀속)</h2>
        <p>
          “서비스”에 대한 저작권 및 지적재산권은 “회사”에 귀속됩니다. 단, “회원”의 “게시물” 및 제휴 계약에 따라 제공된 저작물 등은 제외합니다.
        </p>
        <p>
          “회사”는 서비스와 관련하여 “회원”에게 “회사”가 정한 이용조건에 따라 계정, “아이디”, 콘텐츠, “포인트” 등을 이용할 수 있는 이용권만을 부여하며, “회원”은 이를 양도, 판매, 담보제공 등의 처분행위를 할 수 없습니다.
        </p>

        <h2>제11조(개인정보의 보호 및 사용)</h2>
        <p>
          회사는 회원의 개인정보를 보호하기 위하여 정보통신망법 및 개인정보 보호법 등 관계 법령에서 정하는 바를 준수합니다.
        </p>
        <p>
          회사는 회원의 개인정보를 보호하기 위하여 개인정보처리방침을 제정, 서비스 초기화면에 게시합니다. 개인정보처리방침의 구체적 내용은 별도 공지된 개인정보처리방침 화면을 통하여 볼 수 있습니다.
        </p>
        <p>
          회사는 개인정보처리방침, 위치정보이용약관에 따라 회원의 개인정보 및 개인위치정보를 최대한 보호하기 위하여 노력합니다.
        </p>
        <p>
          회사의 공식 사이트 이외의 링크된 사이트에서는 회사의 개인정보처리방침이 적용되지 않습니다. 링크된 사이트 및 구매 상품이나 서비스를 제공하는 제3자의 개인정보 취급과 관련하여는 해당 사이트 및 제3자의 개인정보처리방침을 확인할 책임이 회원에게 있으며, 회사는 이에 대하여 책임을 부담하지 않습니다.
        </p>
        <p>
          회사는 다음과 같은 경우에 법이 허용하는 범위 내에서 회원의 개인정보를 제3자에게 제공할 수 있습니다.
        </p>
        <ul>
          <li>수사기관이나 기타 정부기관으로부터 정보제공을 요청 받은 경우</li>
          <li>회원의 법령 또는 약관의 위반을 포함하여 부정행위 확인 등의 정보보호 업무를 위해 필요한 경우</li>
          <li>“이벤트”시 제3자에게 이벤트업무에 필요한 회원의 동의를 득한 최소한의 이용자의 정보(성명, 연락처)를 알려주는 경우</li>
          <li>“서비스” 이용 중 별도 동의를 받은 경우(예약중개/간편 결제 등)</li>
          <li>기타법률에 의해 요구되는 경우</li>
        </ul>

        <h2>제12조(별정통신 서비스에 관한 사업자 공지 사항)</h2>
        <p>
          제휴업체와 통화를 위해 사용되는 050 서비스는 KCT 한국케이블텔레콤과 제휴합니다. 서비스 운영 전반의 서버 운영, 데이터베이스 운영은 Amazon Web Services, NHN Toast를 통해 제공됩니다.
        </p>

        <h2>제13조(책임제한)</h2>
        <p>
          “회사”는 “아카데미”/“코치”와 “이용자”간의 상품거래를 중개하는 플랫폼 서비스만을 제공할 뿐, “재화”를 판매하는 당사자가 아니며, “재화”에 대한 정보, 누락, 배송, 하자 등에 대한 책임은 “아카데미”/“코치”에게 있습니다.
        </p>
        <p>
          “회사”는 “아카데미”/“코치”가 게재한 정보, 자료, 사실의 신뢰도, 정확성 등 내용에 관해서는 책임을 지지 않습니다.
        </p>
        <p>
          회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 회원에 발생한 손해에 대해서는 책임을 부담하지 않습니다:
        </p>
        <ul>
          <li>천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</li>
          <li>서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우</li>
          <li>회원의 귀책사유로 서비스 이용에 장애가 있는 경우</li>
          <li>서비스에 대한 접속 및 서비스의 이용과정에서 발생하는 개인적인 손해</li>
          <li>서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해</li>
          <li>서버에 대한 전송 또는 서보로부터의 전송에 대한 제3자의 모든 불법적인 방해 또는 중단행위로부터 발생하는 손해</li>
          <li>제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나, 전송, 유포되도록 한 모든 바이러스 스파이웨어 및 기타 악성 프로그램으로 인한 손해</li>
          <li>전송된 데이터의 오류 생략, 누락 파괴 등으로 발생되는 손해</li>
          <li>회원 간의 회원 상태정보 등록 및 서비스 이용과정에 발생하는 명예훼손 기타 불법행위로 인한 각종 민형사상 책임</li>
          <li>1호 내지 제3호를 제외한 기타 회사의 고의·과실이 없는 사유로 인한 경우</li>
        </ul>
        <p>
          “회사”는 “이용자”의 귀책사유로 인한 “서비스”이용의 장애에 대하여 책임을 지지 않습니다.
        </p>
        <p>
          “회사”는 “이용자”가 게재한 이용후기 등 정보/자료/사실의 신뢰도, 정확성에 대해서는 책임을 지지 않습니다.
        </p>
        <p>
          “회사”는 제3자가 서비스 내 화면 또는 링크된 웹사이트를 통하여 광고한 제품 또는 서비스의 내용과 품질에 대해서 감시할 의무가 없으며, 상품의 내용과 품질에 관련한 어떠한 책임도 지지 않습니다.
        </p>
        <p>
          “회사”는 “이용자”가 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며, 그 밖의 서비스를 통하여 얻은 자료로 인한 손해에 관하여 책임을 지지 않습니다.
        </p>
        <p>
          “회사” 및 “회사”의 임직원 그리고 대리인은 고의 또는 중대한 과실이 없는 한 다음과 같은 사항으로부터 발생하는 손해에 대해 책임을 지지 않습니다:
        </p>
        <ul>
          <li>회원 정보의 허위 또는 부정확성에 기인하는 손해</li>
          <li>서비스에 대한 접속 및 서비스의 이용과정에서 “이용자”의 귀책사유로 발생하는 손해</li>
          <li>서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터 발생하는 손해 및 제3자의 불법적인 행위를 방지하거나 예방하는 과정에서 발생하는 손해</li>
          <li>제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한 모든 바이러스, 스파이웨어 및 기타 악성 프로그램으로 인한 손해</li>
        </ul>
        <p>
          “회사”는 “이용자”가 “아카데미”에 방문하여 서비스 및 상품을 이용하며 “아카데미”와 “코치”에게 끼치는 영업상의 손해는 책임지지 않습니다.
        </p>
        <p>
          “회사”는 통신판매 중개업자로서 상품과 상품 제공에 따른 용역 서비스는 “아카데미”와 “코치”의 책임 하에 제공됩니다.
        </p>
        <p>
          “회사”는 “아카데미”와 “코치”의 서비스 불량 및 하자가 발생했을 경우 “아카데미”와 “코치”는 고객에 대하여 우선적으로 교환, 환불의 책임이 있으며, 이와 관련한 분쟁이 발생하는 경우 ‘하위 셀러’의 책임 하에 동 분쟁을 해결합니다.
        </p>
        <p>
          “회사”의 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 통보해 드립니다.
        </p>
        <p>
          “회사”와 “이용자” 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제 신청이 있는 경우에는 공정거래위원회 또는 인천지방법원의 조정에 따를 수 있습니다.
        </p>

        <h2>제 14 조 (규정의 준용)</h2>
        <p>
          본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.
          본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따릅니다.
        </p>

        <h2>제 15 조 (취소 및 환불 정책)</h2>
        <p>
          서비스 이용에 대한 취소 및 환불규정은 전자상거래 등에서의 소비자 보호에 관한 법령을 준수합니다.
        </p>
        <p>
          “아카데미”와 “코치”는 별도의 취소 및 환불규정을 제정할 수 있으며 이를 상세페이지에 기재하고 “이용자”의 동의를 받은 경우 우선 적용됩니다.
        </p>
        <p>
          “회사”는 “아카데미”와 “코치”에게 전 ➁항의 규정이 없는 경우를 위하여 시설별 취소환불규정을 제정할 수 있으며, 이를 상세 페이지에 기재하고 “이용자”의 동의를 받아 적용합니다.
        </p>
        <p>“회사”가 제정하는 취소환불규정은 아래와 같습니다:</p>
        <ul>
          <li>“이용자”가 구매한 상품 이용시간 2시간 전까지 “회사”는 취소 수수료를 부과하지 않습니다.</li>
          <li>“이용자”가 구매한 상품 이용시간 2시간 이내에는 취소 수수료 100%를 부과합니다.</li>
          <li>“이용자”가 구매한 상품 이용시간에 “아카데미”에 나타나지 않을 경우 환불이 불가합니다.</li>
          <li>“이용자”가 구매한 상품을 “아카데미”에서 제공받기 시작한 이후에는 환불이 불가합니다.</li>
        </ul>
        <p>
          “회사”는 고객의 프로모션과 마케팅을 위하여 ‘쿠폰’ 및 ‘포인트’를 제공합니다. 이때, 쿠폰과 포인트는 부여 시 고지된 이용 기간에 한하여 이용가능하며 이용기간이 초과하는 경우 삭제됩니다. 또한 잔여 포인트와 쿠폰은 휴면 계정 전환 시에 이용기한과 관계없이 초기화 됩니다.
        </p>

        <h2>제 16 조 (포인트 등 제도 운영)</h2>
        <p>
          캐치비는 포인트의 적립 기준, 사용 방법, 사용 기간 및 제한에 대한 사항을 사이트에 별도로 게시하거나 통지합니다. 포인트의 적립이나 사용 등에 관한 구체적 사항은 캐치비의 정책에 따라 달라질 수 있으며, 포인트 관련 정책이 변경되는 경우 이를 변경 전 공지합니다.
        </p>
        <p>
          “회원”이 “회사”가 승인하지 않은 방법 또는 위계 등 부정한 방법(매크로, 다수 생성 ID 사용 등)으로 적립금을 획득하거나 부정한 목적/용도로 적립금을 사용하는 경우, 적립금의 사용 제한, 적립금 회수, 적립금을 사용한 구매 계약 취소 또는 회원 자격의 정지/상실 등의 조치를 취할 수 있습니다. 또한, 회원의 행위로 인하여 캐치비에게 발생한 손해의 배상을 청구할 수 있으며, 형사 고발 등 기타 조치를 취할 수 있습니다.
        </p>
        <h3>포인트 취득</h3>
        <p>
          “회사”는 “회원”이 구매 활동 · 구매 후기 작성· 이벤트 참여를 하거나, 처리 지연 또는 기타 서비스 불편을 겪은 경우 “회사”가 정한 기준에 따라 일정한 포인트를 부여할 수 있습니다.
        </p>
        <p>
          이용권 구매 적립 포인트는 포인트 부여를 안내한 재화 등의 판매가에 대하여 특정한 비율만큼 포인트를 부여합니다.
        </p>
        <p>
          타인의 회원 정보 도용으로 사용된 적립금 및 예치금과 관련하여 회사는 어떠한 책임도 지지 않습니다.
        </p>

        <h3>포인트의 사용</h3>
        <p>
          지급한 포인트는 “회사” 내 “아카데미” 예약 및 “레슨” 이용권 구매에서만 사용할 수 있으며, 적립해 드린 포인트는 현금으로 지급/환급되지 않습니다.
        </p>
        <p>
          1원 이상 적립 시 사용할 수 있으며, 포인트 사용 시에는 이용권 구매 적립 포인트를 지급하지 않습니다. (단, 상품 결제액이 1원 이상 시 사용할 수 있습니다.)
        </p>
        <p>
          포인트는 다른 결제 수단(신용카드, 모바일)과 함께 사용할 수 있습니다. 단, 2가지 결제 방법에 한해서 복합 결제할 수 있습니다. (최소 결제 금액은 결제사 정책에 따라 변경될 수 있습니다.)
        </p>
        <p>
          “회원”은 포인트를 제3자에게 또는 다른 아이디로 양도할 수 없으며 유상으로 거래하거나 현금으로 전환할 수 없습니다.
        </p>

        <h3>포인트의 소멸</h3>
        <p>
          “회사”로부터 받은 포인트는 회사가 별도로 유효기간을 정한 때에는 이에 따릅니다. 유효기간이 지난 포인트는 자동으로 소멸합니다.
        </p>
        <p>
          적립된 시점을 기준으로 1년이 지난 포인트는 자동으로 소멸합니다. 단, “회원”에게 30일 전 미리 고지할 의무가 있으며, 30일 전 미리 고지하지 못하였을 때 고지일 30일 후 소멸합니다. “회사”에서 이메일(E-mail)로 통지하며, 개인정보 불일치 및 이메일 수신 거부로 이메일을 수신하지 못하는 경우 회사는 어떠한 책임도 지지 않습니다.
        </p>
        <p>
          “회원” 탈퇴 시 사용하지 않은 포인트는 즉시 소멸하며, 탈퇴 후 재가입하더라도 소멸한 포인트는 복구되지 아니합니다.
        </p>
        <p>
          포인트를 이용하여 부당 이득을 취하거나 악의적인 상거래가 발생할 경우, 회원 탈퇴 또는 해당 포인트의 지급을 중지 또는 환수할 수 있습니다.
        </p>
        <p>
          “회원”이 구매 또는 이벤트 등으로 받은 포인트를 사용한 이후 해당 적립 원인이 취소(상품 등의 구매 취소, 부정 적립 사유 발견 등)되는 경우, “회원”은 이에 상응하는 금액을 캐치비에 즉시 반환하여야 하며, 회원이 보유하는 잔여 적립금 또는 추후 적립되는 적립금에서 해당 금액만큼 적립금이 차감될 수 있습니다.
        </p>

        <h2>제 17 조 (회사의 연락처)</h2>
        <p>회사의 상호 및 주소 등은 다음과 같습니다:</p>
        <ul>
          <li>상 호 : 주식회사 스윕시리즈</li>
          <li>대 표 자 : 홍승우</li>
          <li>주 소 : 인천광역시 서구 청라한내로 72번길 17 4층</li>
          <li>연락처 : sweepseries.com</li>
        </ul>
      </>
    ),
  },
  // 추가적인 버전의 서비스 이용약관도 이곳에 추가할 수 있습니다.
  '2023-01-01': {
    title: '서비스 이용약관',
    content: (
      <>
        <p>
          이 서비스 이용약관은 2023년 01월 01일부터 2024년 08월 31일까지 적용되었습니다.
        </p>
        <h2>제 1 조 (서비스 이용약관)</h2>
        <p>“회사”는 다음의 서비스 이용약관을 적용합니다...</p>
        {/* 구 버전의 내용 */}
      </>
    ),
  },
} as const;

// 객체의 키를 추출하여 특정 문자열 리터럴 유형으로 정의
type TermsOfServiceDate = keyof typeof termsOfServices;

const TermsOfService: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<TermsOfServiceDate>('2024-09-01');

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(event.target.value as TermsOfServiceDate);
  };

  useEffect(() => {
    // 페이지가 로드될 때 맨 위로 스크롤
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms-service-wrapper">
      <Link to="/" className="logo-link"> {/* 첫 화면의 경로로 설정 */}
        <img src={catchb} alt="Catch B" className="terms-logo" />
      </Link>
      <div className="terms-container">
        <div className="terms-date-selector">
          <label htmlFor="date-selector">서비스 이용약관 버전:</label>
          <select id="date-selector" value={selectedDate} onChange={handleDateChange}>
            {Object.keys(termsOfServices).map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
        </div>
        <h1>{termsOfServices[selectedDate].title}</h1>
        {termsOfServices[selectedDate].content}
      </div>
    </div>
  );
};

export default TermsOfService;
