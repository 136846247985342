import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './PrivacyPolicy.css';
import catchb from '../assets/images/catchb.svg'; // 로고 이미지 추가

// 개인정보 처리방침 데이터를 날짜별로 관리
const privacyPolicies = {
  '2024-09-01': {
    title: '개인정보 처리방침',
    content: (
      <>
        <p>
          주식회사 스윕시리즈(서비스명: ‘Catch B’, 이하 “회사”)은(는) 「개인정보 보호법」 제30조에 따라
          정보주체의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여
          다음과 같이 개인정보 처리 방침을 수립·공개합니다.
        </p>
        <p>이 개인정보처리 방침은 2024년 09월 01일부터 적용됩니다.</p>

        <h2>제 1 조 (개인정보의 처리 목적)</h2>
        <p>“회사”는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며 이용 목적이 변경되는 경우에는 「개인정보 보호법」 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.</p>
        <ul>
          <li>홈페이지/app 회원가입 및 관리: 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만 14세 미만 아동의 개인정보 처리 시 법정대리인의 동의 여부 확인 목적으로 개인정보를 처리합니다.</li>
          <li>민원사무 처리: 민원사항 확인 목적으로 개인정보를 처리합니다.</li>
          <li>재화 또는 서비스 제공: 서비스 제공, 콘텐츠 제공, 맞춤 서비스 제공, 요금 결제·정산을 목적으로 개인정보를 처리합니다.</li>
          <li>마케팅 및 광고에의 활용: 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.</li>
        </ul>

        <h2>제 2 조 (개인정보의 처리 및 보유 기간)</h2>
        <p>“회사”는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리·보유합니다.</p>
        <ul>
          <li>홈페이지/app 회원가입 및 관리와 관련한 개인정보는 수집·이용에 관한 동의일로부터 <strong>3년</strong>까지 위 이용목적을 위하여 보유·이용됩니다.</li>
        </ul>

        <h2>제 3 조 (개인정보의 제3자 제공)</h2>
        <p>“회사”는 개인정보를 제1조(개인정보의 처리 목적)에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보 보호법」 제17조 및 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공합니다.</p>
        <ul>
          <li>개인정보를 제공받는 자: 주식회사 스윕시리즈</li>
          <li>제공받는 자의 개인정보 이용목적: 비밀번호, 로그인 ID, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제 기록</li>
          <li>제공받는 자의 보유·이용기간: 3년</li>
        </ul>

        <h2>제 4 조 (개인정보처리 위탁)</h2>
        <p>“회사”는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행 목적 외 개인정보 처리 금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.</p>
        <p>위탁업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본 개인정보 처리 방침을 통하여 공개하도록 하겠습니다.</p>

        <h2>제 5 조 (정보주체와 법정대리인의 권리·의무 및 그 행사방법)</h2>
        <p>정보주체는 주식회사 스윕시리즈에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</p>
        <ul>
          <li>제1항에 따른 권리 행사는 주식회사 스윕시리즈에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 주식회사 스윕시리즈는 이에 대해 지체 없이 조치하겠습니다.</li>
          <li>제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다.</li>
          <li>개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.</li>
          <li>개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
          <li>주식회사 스윕시리즈는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
        </ul>

        <h2>제 6 조 (처리하는 개인정보의 항목 작성)</h2>
        <p>“회사”는 다음의 개인정보 항목을 처리하고 있습니다.</p>
        <ul>
          <li>필수항목: 이메일, 비밀번호, 로그인 ID, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제 기록</li>
          <li>선택항목: 없음</li>
        </ul>

        <h2>제 7 조 (개인정보의 파기)</h2>
        <p>“회사”는 개인정보 보유기간의 경과, 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.</p>
        <p>정보주체로부터 동의 받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.</p>
        <p>개인정보 파기의 절차 및 방법은 다음과 같습니다:</p>
        <ul>
          <li>파기절차: “회사”는 파기 사유가 발생한 개인정보를 선정하고, “회사”의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</li>
          <li>파기방법: 전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다.</li>
        </ul>

        <h2>제 8 조 (개인정보의 안전성 확보 조치)</h2>
        <p>“회사”는 개인정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다:</p>
        <ul>
          <li>개인정보 취급 직원의 최소화 및 교육: 개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화하여 개인정보를 관리하는 대책을 시행하고 있습니다.</li>
          <li>내부관리계획의 수립 및 시행: 개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다.</li>
          <li>해킹 등에 대비한 기술적 대책: “회사”는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안 프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.</li>
          <li>개인정보의 암호화: 이용자의 개인정보는 비밀번호는 암호화되어 저장 및 관리되고 있으며, 중요한 데이터는 파일 및 전송 데이터를 암호화하거나 파일 잠금 기능을 사용하는 등의 별도 보안 기능을 사용하고 있습니다.</li>
          <li>접속기록의 보관 및 위변조 방지: 개인정보처리 시스템에 접속한 기록을 최소 1년 이상 보관, 관리하고 있으며, 다만, 5만명 이상의 정보주체에 관하여 개인정보를 추가하거나, 고유 식별정보 또는 민감정보를 처리하는 경우에는 2년 이상 보관, 관리하고 있습니다. 또한, 접속기록이 위변조 및 도난, 분실되지 않도록 보안 기능을 사용하고 있습니다.</li>
          <li>비인가자에 대한 출입 통제: 개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.</li>
        </ul>

        <h2>제 9 조 (개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항)</h2>
        <p>“회사”는 이용자에게 개별적인 맞춤 서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
        <p>쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다.</p>
        <ul>
          <li>쿠키의 사용 목적: 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.</li>
          <li>쿠키의 설치·운영 및 거부: 웹브라우저 상단의 도구 {'>'} 인터넷 옵션 {'>'} 개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부할 수 있습니다.</li>
          <li>쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
        </ul>

        <h2>제 10 조 (개인정보 보호책임자)</h2>
        <p>“회사”는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다:</p>
        <ul>
          <li>▶ 개인정보 보호책임자: 성명: 000, 직책: 개인정보 보호 책임자, 직급: CGO, 연락처: 010-0000-0000, support@sweepseries.com</li>
          <li>▶ 개인정보 보호 담당부서: 부서명: 개인정보 보호 담당, 담당자: 000, 연락처: 010-0000-0000, support@sweepseries.com</li>
        </ul>

        <h2>제 11 조 (개인정보 열람청구)</h2>
        <p>정보주체는 ｢개인정보 보호법｣ 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. “회사”는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.</p>
        <ul>
          <li>▶ 개인정보 열람청구 접수·처리 부서: 부서명: 개인정보 보호 담당, 담당자: 000, 연락처: 010-0000-0000, support@sweepseries.com</li>
        </ul>

        <h2>제 12 조 (권익침해 구제방법)</h2>
        <p>정보주체는 개인정보 침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보 침해의 신고, 상담에 대하여는 아래의 기관에 문의하시기 바랍니다:</p>
        <ul>
          <li>개인정보분쟁조정위원회 : (국번 없이) 1833-6972 (www.kopico.go.kr)</li>
          <li>개인정보침해신고센터 : (국번 없이) 118 (privacy.kisa.or.kr)</li>
          <li>대검찰청 : (국번 없이) 1301 (www.spo.go.kr)</li>
          <li>경찰청 : (국번 없이) 182 (ecrm.cyber.go.kr)</li>
        </ul>

        <h2>제 13 조 (개인정보 처리방침 변경)</h2>
        <p>이 개인정보 처리방침은 2024년 09월 01일부터 적용됩니다.</p>
      </>
    ),
  },
  // 추가적인 버전의 개인정보 처리방침도 이곳에 추가할 수 있습니다.
  '2023-01-01': {
    title: '개인정보 처리방침',
    content: (
      <>
        <p>
          이 개인정보처리 방침은 2023년 01월 01일부터 2024년 08월 31일까지 적용되었습니다.
        </p>
        <h2>제 1 조 (개인정보의 처리 목적)</h2>
        <p>“회사”는 다음의 목적을 위하여 개인정보를 처리합니다...</p>
        {/* 구 버전의 내용 */}
      </>
    ),
  },
} as const;

// 객체의 키를 추출하여 특정 문자열 리터럴 유형으로 정의
type PrivacyPolicyDate = keyof typeof privacyPolicies;

const PrivacyPolicy: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<PrivacyPolicyDate>('2024-09-01');

  const handleDateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDate(event.target.value as PrivacyPolicyDate);
  };
  
  useEffect(() => {
    // 페이지가 로드될 때 맨 위로 스크롤
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="privacy-policy-wrapper">
      <Link to="/" className="logo-link"> {/* 첫 화면의 경로로 설정 */}
        <img src={catchb} alt="Catch B" className="privacy-logo" />
      </Link>
      <div className="privacy-container">
        <div className="privacy-date-selector">
          <label htmlFor="date-selector">개인정보 처리방침 버전:</label>
          <select id="date-selector" value={selectedDate} onChange={handleDateChange}>
            {Object.keys(privacyPolicies).map((date) => (
              <option key={date} value={date}>
                {date}
              </option>
            ))}
          </select>
        </div>
        <h1>{privacyPolicies[selectedDate].title}</h1>
        {privacyPolicies[selectedDate].content}
      </div>
    </div>
  );
};

export default PrivacyPolicy;