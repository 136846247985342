import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PreOrderForm.css";
import catchb from "../assets/images/catchb.svg"; // 이미지 파일을 경로에서 임포트

const PreOrderForm: React.FC = () => {
  const [academyName, setAcademyName] = useState("");
  const [representativeName, setRepresentativeName] = useState("");
  const [representativePhone, setRepresentativePhone] = useState("");
  const [representativeEmail, setRepresentativeEmail] = useState(""); // 이메일 상태 추가
  const [agreedToPrivacy, setAgreedToPrivacy] = useState(false);
  const [agreedToBetaService, setAgreedToBetaService] = useState(false); // 베타 서비스 동의 체크박스 상태
  const [isExpanded, setIsExpanded] = useState(false);
  const [isBetaExpanded, setIsBetaExpanded] = useState(false); // 베타 서비스 동의 텍스트 열림 상태

  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0); // 페이지가 로드될 때 스크롤을 맨 위로 이동시킴
  }, []);

  const handlePhoneInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, "");
    if (input.length <= 3) {
      setRepresentativePhone(input);
    } else if (input.length <= 7) {
      setRepresentativePhone(`${input.slice(0, 3)}-${input.slice(3)}`);
    } else {
      setRepresentativePhone(
        `${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7, 11)}`
      );
    }
  };

  const isValidEmail = (email: string) => {
    // TODO: 이거 완성하기
    //const emailRegex = /^[a-zA-Z0-9]+([._+-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+.[a-zA-Z]{2,}$/; // 간단한 이메일 유효성 검사를 위한 정규식
    //return emailRegex.test(email);

    return email;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!agreedToPrivacy) {
      alert("사전입점 신청을 위해 개인정보 수집 및 이용에 동의해주세요.");
      return;
    }

    if (!agreedToBetaService) {
      alert(
        "사전입점 신청을 위해 베타 서비스 정보 등록 및 이용에 동의해주세요."
      );
      return;
    }

    if (representativePhone.length < 13) {
      alert("올바른 전화번호를 입력해주세요.");
      return;
    }

    if (!isValidEmail(representativeEmail)) {
      alert("올바른 이메일 형식을 입력해주세요.");
      return;
    }

    const scriptURL =
      "https://script.google.com/macros/s/AKfycbz1ITae4fHxHADuEnvIHAnfL4FNX97YtKe0WDxEjir4ZvHUPBTX6MgQ0YJLcBonQGfTLQ/exec";

    const data = new URLSearchParams();
    data.append("academyName", academyName);
    data.append("representativeName", representativeName);
    data.append("representativePhone", representativePhone);
    data.append("representativeEmail", representativeEmail); // 이메일 추가

    try {
      const response = await fetch(scriptURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded", // URL-encoded 방식으로 전송
        },
        body: data.toString(),
      });

      if (!response.ok) {
        throw new Error("서버에서 오류 응답을 받았습니다.");
      }

      const result = await response.json();
      console.log("응답 데이터:", result); // 응답 데이터 로그 확인

      if (response.ok) {
        alert("사전입점 신청이 완료되었습니다!");
        navigate("/");
      } else {
        alert("데이터 저장 중 오류가 발생했습니다.");
      }
    } catch (error) {
      alert("데이터 전송에 실패했습니다.");
      console.error("전송 오류:", error);
    }
  };

  return (
    <div className="pre-order-form-container">
      {/* catchb 이미지 추가 */}
      <div className="pre-order-form-header">
        <img src={catchb} alt="Catch B Logo" className="catch-logo" />
        <h1>아카데미 사전입점 신청</h1>
      </div>
      <form onSubmit={handleSubmit} className="pre-order-form">
        <div className="form-group">
          <label htmlFor="academyName">아카데미명</label>
          <input
            type="text"
            id="academyName"
            value={academyName}
            onChange={(e) => setAcademyName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="representativeName">대표자 성명</label>
          <input
            type="text"
            id="representativeName"
            value={representativeName}
            onChange={(e) => setRepresentativeName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="representativePhone">대표자 전화번호</label>
          <input
            type="tel"
            id="representativePhone"
            value={representativePhone}
            onChange={handlePhoneInput}
            placeholder="01012345678 (숫자만 입력)"
            required
          />
        </div>

        {/* 대표자 이메일 입력 필드 */}
        <div className="form-group">
          <label htmlFor="representativeEmail">대표자 이메일</label>
          <input
            type="email"
            id="representativeEmail"
            value={representativeEmail}
            onChange={(e) => setRepresentativeEmail(e.target.value)}
            placeholder="example@domain.com"
            required
          />
        </div>

        {/* 개인정보 수집 동의 체크박스 */}
        <div className="form-group">
          <div
            className={`form-group-checkbox ${isExpanded ? "expanded" : ""}`}
          >
            <div className="checkbox-container">
              <div className="checkbox-text">
                <input
                  type="checkbox"
                  id="privacyConsent"
                  checked={agreedToPrivacy}
                  onChange={(e) => setAgreedToPrivacy(e.target.checked)}
                />
                <label htmlFor="privacyConsent">
                  개인정보 수집 및 이용에 동의합니다.
                </label>
              </div>
              <button
                type="button"
                className="expand-button"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? "[접기]" : "[보기]"}
              </button>
            </div>
            {isExpanded && (
              <div className="privacy-details">
                <p>
                  개인정보 수집 및 이용에 동의해주세요.
                  <br />
                  동의한 사람에 한해 사전 입점 이벤트가 적용됩니다.
                </p>
                <br />
                <p>1. 수집 목적: 사전 입점 이벤트 참여자 혜택 제공</p>
                <p>2. 수집 항목: 아카데미명, 대표자 성명, 대표자 전화번호</p>
                <p>3. 보유 및 이용 기간: 입력일로부터 1년까지</p>
              </div>
            )}
          </div>
        </div>

        {/* 베타 서비스 정보 등록 및 이용 동의 체크박스 */}
        <div className="form-group">
          <div
            className={`form-group-checkbox ${
              isBetaExpanded ? "expanded" : ""
            }`}
          >
            <div className="checkbox-container">
              <div className="checkbox-text">
                <input
                  type="checkbox"
                  id="betaServiceConsent"
                  checked={agreedToBetaService}
                  onChange={(e) => setAgreedToBetaService(e.target.checked)}
                />
                <label htmlFor="betaServiceConsent">
                  베타 서비스 정보 등록 및 이용에 동의합니다.
                </label>
              </div>
              <button
                type="button"
                className="expand-button"
                onClick={() => setIsBetaExpanded(!isBetaExpanded)}
              >
                {isBetaExpanded ? "[접기]" : "[보기]"}
              </button>
            </div>
            {isBetaExpanded && (
              <div className="privacy-details">
                <p>
                  1. 목적 및 수집하는 정보의 항목
                  <br />
                  ▶ 수집 목적: 베타 서비스 등록, 베타 서비스 이용 시 피드백 제공
                  및 서비스 개선
                  <br />
                  ▶ 수집 항목:
                  <br />
                  - 아카데미명, 대표자 성명, 대표자 전화번호, 이메일 주소
                  <br />
                  - 베타 서비스 이용 기록 및 피드백 내용
                  <br />- 기타 서비스 이용 중 생성되는 데이터 (ex. 접속기록,
                  오류 발생기록, 선호도 등)
                </p>
                <p>
                  2. 정보의 이용 목적
                  <br />
                  ▶ 베타 서비스 가입자 등록 및 서비스 제공
                  <br />
                  ▶ 베타 서비스의 개선 및 정식 출시 준비를 위한 피드백 수집
                  <br />
                  ▶ 가입자의 서비스 이용 패턴 분석 및 서비스 최적화
                  <br />
                  ▶ 베타 서비스 관련 공지사항 및 문의사항 대응
                  <br />▶ 베타 서비스 가입자의 고객 정보 분석
                </p>
                <p>
                  3. 보유 및 이용 기간
                  <br />
                  베타 서비스 종료 후에도 수집된 정보는 서비스 개선 및 문제
                  해결을 위한 분석 자료로 일정 기간 동안 보유될 수 있습니다. 단,
                  정보주체가 별도로 삭제를 요청할 경우 즉시 파기합니다.
                  <br />▶ 보유 기간: 서비스 정식 출시 후 1년 또는 정보 주체의
                  삭제 요청 시
                </p>
                <p>
                  4. 제3자 제공에 대한 안내
                  <br />
                  회사는 원칙적으로 귀하의 정보를 제3자에게 제공하지 않습니다.
                  다만 서비스 개선을 위한 연구 목적 또는 법적 요구가 있을 경우
                  제공될 수 있습니다.
                </p>
                <p>
                  5. 동의 거부 권리 및 불이익
                  <br />
                  귀하는 본 동의서에 대한 동의를 거부할 수 있으며, 이 경우
                  사전입점 이벤트에 참여할 수 없습니다.
                </p>
              </div>
            )}
          </div>
        </div>

        <button type="submit" className="submit-button">
          사전입점 신청
        </button>
      </form>
    </div>
  );
};

export default PreOrderForm;
