import React from 'react';
import { Element } from 'react-scroll';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // 최신 react-router-dom 사용
import Header from './containers/Header';
import AppIntro from './containers/AppIntro';
import Event from './containers/Event';
import PreOrder from './containers/PreOrder';
import Footer from './containers/Footer';
import NavBar from './containers/NavBar';
import TermsOfService from './containers/TermsOfService';
import PrivacyPolicy from './containers/PrivacyPolicy';
import PreOrderForm from './containers/PreOrderForm';
import './App.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={
            <>
              <Element name="Header" className="Section">
                <NavBar />
                <Header />
              </Element>
              <Element name="appIntro" className="Section">
                <AppIntro />
              </Element>
              <Element name="event" className="Section">
                <Event />
              </Element>
              <Element name="preOrder" className="Section">
                <PreOrder />
              </Element>
              <Footer />
            </>
          } />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/pre-order-form" element={<PreOrderForm />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
