import React from "react";
import { useNavigate } from "react-router-dom"; // 최신 react-router-dom 사용
import "./Footer.css";
import naverLogo from "../assets/images/naver.svg";
import instagramLogo from "../assets/images/instragram.svg";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  const navigateTo = (path: string) => {
    navigate(path);
  };

  return (
    <footer className="footer-container">
      <div className="footer-text-container">
        <div className="footer-main">
          <p className="footer-head">(주)스윕시리즈</p>
          <div className="social-icons">
            <span
              className="footer-link"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/catch.b__official/",
                  "_blank",
                  "noopener noreferrer"
                )
              }
            >
              <img
                src={instagramLogo}
                alt="인스타그램"
                className="social-logo"
              />
            </span>
            <span
              className="footer-link"
              onClick={() =>
                window.open(
                  "https://blog.naver.com/catchbaseball",
                  "_blank",
                  "noopener noreferrer"
                )
              }
              onKeyDown={() => {}}
            >
              <img
                src={naverLogo}
                alt="네이버 블로그"
                className="social-logo"
              />
            </span>
          </div>
        </div>
        <p>사업자 등록번호 354-87-03036 | 대표 홍승우</p>
        <p>인천 서구 청라한내로72번길 17 416호</p>
        <div className="footer-links">
          <span
            className="footer-link"
            onClick={() => navigateTo("/terms")}
            onKeyDown={() => {}}
          >
            서비스 이용약관
          </span>
          <span
            className="footer-link"
            onClick={() => navigateTo("/privacy")}
            onKeyDown={() => {}}
          >
            개인정보 처리방침
          </span>
          {/* 모바일 공지사항 버튼 */}
          <span
            className="mobile-notice-button"
            onClick={() => alert("공지사항 버튼 클릭")}
            onKeyDown={() => {}}
          >
            공지사항
          </span>
        </div>
      </div>

      {/* PC 버전 공지사항 */}
      <div className="footer-notice">
        <p className="notice-title">공지사항</p>
        <hr className="notice-divider" />
        <p className="notice-text">최근 등록된 공지사항이 없습니다.</p>
      </div>

      {/* 저작권 표시 */}
      <div className="footer-copyright">
        © 2024 SWEEP series. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
